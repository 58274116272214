import type { ISbStoriesParams, ISbStoryData, StoryblokBridgeConfigV2 } from '@storyblok/vue'
import { defu } from 'defu'
import type { NitroFetchOptions, NitroFetchRequest } from 'nitropack'
import { isEmpty, pick } from 'lodash-es'

type UsePageOptions = {
  apiOptions?: ISbStoriesParams
  bridgeOptions?: StoryblokBridgeConfigV2
  asyncOptions?: NitroFetchOptions<ISbStoryData>
  request?: NitroFetchRequest
}

function hasMetadata(obj: Record<string, any>) {
  const defaultMetadata = pick(obj, ['title', 'description'])
  const ogMetadata = pick(obj, ['og_title', 'og_description'])

  return Object.values(defaultMetadata).some(value => !isEmpty(value)) || Object.values(ogMetadata).some(value => !isEmpty(value))
}

const usePageState = (uniqueKey: string) => useState<ISbStoryData>(`${uniqueKey}-state`)

export const usePage = async (options?: UsePageOptions) => {
  const apiOptions = options?.apiOptions ?? {}
  const bridgeOptions = options?.bridgeOptions ?? {}
  const asyncOptions = options?.asyncOptions ?? {}
  const request = options?.request ?? null

  let currentPath: NitroFetchRequest

  if (request) {
    currentPath = request
  }
  else {
    const route = useRoute()
    currentPath = route.path === '/' || route.path === '' ? '/home' : route.path
  }

  const params = defu(apiOptions, {})
  const uniqueKey = `${JSON.stringify(params)}${currentPath}`
  const story = usePageState(uniqueKey)
  const metadata = computed(() => story.value.content.metadata ?? {})

  function fetchStory() {
    return $fetch<ISbStoryData>(`/api${currentPath}`, {
      ...asyncOptions,
      params,
    })
  }

  onMounted(() => {
    if (story.value && story.value.id) {
      useStoryblokBridge(
        story.value.id,
        evStory => (story.value = evStory),
        {
          ...bridgeOptions,
          preventClicks: true,
        },
      )
    }
  })

  if (!story.value) {
    const data = await fetchStory()

    // if (error.value?.statusCode && error.value?.statusCode >= 400 && error.value?.statusCode < 600) {
    //   throw createError({
    //     statusCode: error.value?.statusCode,
    //     statusMessage: error.value?.statusMessage,
    //   })
    // }

    if (data) {
      story.value = data
    }
  }

  // if (metadata.value && tryUseNuxtApp()) {
  //   if (hasMetadata(metadata.value)) {
  //     useSeoMeta({
  //       title: () => metadata.value?.title,
  //       content: () => metadata.value.description || metadata.value.og_description,
  //       ogTitle: () => metadata.value.og_title || metadata.value?.title,
  //       ogDescription: () => metadata.value.og_description || metadata.value.description,
  //     })
  //
  //     useHead({
  //       title: () => metadata.value?.title,
  //       templateParams: {
  //         category: null,
  //       },
  //     })
  //   }
  // }

  return story
}
